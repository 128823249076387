@import "./variables.scss";

@media (max-width: 1650px) {
  .desktopNav {
    display: none;
  }

  .mobileNavMenu {
    display: block;
  }
  .mobileNav {
    text-align: center;
    background: $primary;
  }

  .mobileNav .logo {
    width: 100px;
  }

  .mobileNavMenu {
    display: block;
  }

  .mobileNavLinks .MuiListItemText-root {
    text-align: center;

  }

  .mobileNavLinks a {
    text-decoration: none;
    color: $white;
    font-size: 16px;
  }
  .mobileNavLinks a:link {
    text-decoration: none;
    color: $white;
    font-size: 16px;
  }

  .mobileNavLinks a:hover {
    color: #ffffff;
 
  }

  /* Menu Icon */
  .menuHamIcon {
    margin-top: -10px;
    text-align: center;
  }

  .aboutAIContainer .aboutTitle {
    color: $primary;
    margin: 125px auto;
    font-size: 65px;
    position: absolute;
    padding-left: 50px;
    background: transparent;
    padding: 20px;
    border-radius: 5px;
    text-align: left;
  }

  .ProfileCard img {
    text-align: center;
    margin: 25px auto;
    height: 200px;
    border-radius: 10px;
    box-shadow: 5px 10px 8px 5px rgb(113, 113, 113);

  }

    /* Capabilities */

  .capCard {
    width: auto;
    height: 600px;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
  }

  .capabilitiesContainer {
    height: 100%;
    padding-bottom: 25px;
  }


    .Card h5 {
      color: $primary;
      font-size: 18px;
      width: 85%;
      height: 75px;
      margin: 0 auto;
      padding: 20px;
      border-radius: 10px 10px 0 0;
      color: #ffffff;
      word-wrap: break-word;
    }

    .aboutAIContainer {
      background-image: linear-gradient(to bottom, #ffffff, #e0eff9);
      width: 100%;
      height: 100%;
      padding-bottom: 50px;
    }


}

@media (max-width: 1000px) {
  .mobileNavLinks .MuiListItemText-root {
    text-align: center;
  }

  .mobileNavLinks a {
    text-decoration: none;
    color: $white;
    font-size: 16px;
  }
  .mobileNavLinks a:link {
    text-decoration: none;
    color: $white;
    font-size: 15px;
  }

  .mobileNavLinks a:hover {
    color: #ffffff;
  }

  .sectionTitle {
    margin: 0 auto;
    color: $h1;
    font-size: 24px;
    text-align: center;
    padding: 15px;
    font-style: normal;
    font-family: sans-serif;
  }

  /* Header Bar Section */
  .headerBar img {
    width: 80px;
    height: auto;
    margin-top: 10px;
  }

  .headerBar .button {
    width: 130px;
    font-size: 14px;
    height: auto;
    margin-top: 6px;
    float: right;
  }

  /* Footer Section */
  .footer {
    background: #f9f9f9;
    height: 100px;
    clip-path: ellipse(125% 100% at 98.45% 0%);
  }

  .footer img {
    margin-top: 25px;
    float: left;
    width: 80px;
    padding-left: 10px;
  }

  .siteLogo {
    color: $white;
    margin-top: -27px;
    font-size: 18px;
    padding-left: 5px;
  }

  .appWrapper p {
    font-size: 14px;
    margin: 0 auto;
    width: 90%;
    line-height: normal;
    font-family: sans-serif;
  }

  .appWrapper h3 {
    font-size: 24px;
  }

  .MuiButton-root {
    background: $button;
    color: $white;
    font-size: 12px;
    width: 150px;
    text-align: center;
    display: block;
    margin: 0 auto;
    margin-bottom: 0;
    overflow: hidden;
    position: relative;
    transform: translatez(0);
  }

  /* Menu Icon */
  .menuHamIcon {
    margin-top: -10px;
    text-align: center;
  }

  /* Nav Bar */

  .navBar {
    margin-top: 10px;
  }

  .navLogo {
    width: 105px;
    height: 40px;
  }

  /* Page Title */

  .pageTitleSection {
    color: $pageTitleColor;
    font-size: $pageTitleFontSize;
    text-align: $left;
    text-transform: $pageTitleUppercase;
    background-image: $pageTitleBckGrdImg;
    padding: $pageTitlePadding;
  }

  /* Page Content */

  .aboutAIContainer {
    background-image: linear-gradient(to bottom, #ffffff, #e0eff9);
    width: 100%;
    height: 100%;
    padding-bottom: 50px;
  }

  .aboutAIContainer img {
    margin-top: -30px;
  }

  .aboutAIContainer .aboutTitle {
    color: $primary;
    margin: 50px auto;
    font-size: 45px;
    position: absolute;
    padding-left: 50px;
    background: transparent;
    padding: 20px;
    border-radius: 5px;
    text-align: left;
  }

  .aboutAIContainer .aboutTitle .line {
    background: $aboutAiLineBackground;
    color: $aboutAiLineColor;
    display: $aboutAiLineDisplay;
    padding: $aboutAiLinePadding;
    text-decoration: $aboutAiLineTextDecoration;
    transition: $aboutAiLineTransition;
  }

  .aboutAIContainer .banner:hover {
    color: $aboutAiBannerHoverColor;
    --underline-width: 100%;
  }
/* ApproachAI */ 
.approachAIContainer {
  background-image: linear-gradient(to bottom, #e0eff9, #e8f6ff);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  word-wrap: break-word;
}

.approachAIContainer .container {
margin: 0 auto;
}


.approachAIContainer h3 {
font-size: 35px;
}

.approachAIContainer h3 .siteTitle {
 color: $primary;
}


.approachAIContainer .columns {
text-align: left;
padding: 30px;
margin: 0 auto;
}

.approachAIContainer .columns .title {
color: $primary;
font-size: 18px;
}

.approachAIContainer img {
width: 100%;
text-align: center;
margin: 0 auto;
border-radius: 5px;
}


.linkBox {
display: inline-block;
position: relative;

z-index: 0;
transition: all .3s ease;
opacity: 1;
margin-right:  -1px;

&:hover {
z-index: 10;


}

&:before, &:after {
content: "";
display: block;
position: absolute;
// background: $black;
top:  50%;
left: 50%;
transform: translate(-50%, -50%);
animation-iteration-count: 1;
transform-origin: 50% 50%;
opacity: 1;

}
&:before {
width: calc(100% + 2px);
height: 100%;
z-index: 1;
transition: height 1s ease, opacity .8s ease;

}

&:after {
height: calc(100% + 2px);
width: 100%;
z-index: 1;
transition: width 1s ease, opacity .8s ease;

}

&:hover, &:focus{
&:before {
  transition: height .2s ease , opacity .3s ease;
  height: 85%;
  opacity: .7;
  
}

&:after {
   transition: width .2s ease,  opacity .3s ease;
   width: 85%;
   opacity: .8;
}
}

&__image {
width: 60%;
will-change: width;
position: absolute;
top:  50%;
left: 50%;
transform: translate(-50%, -50%)  scale(1);
transition: all .5s ease;
z-index: 2;
}
}


.approachAIContainer .linkBox{
color: $primary;
text-decoration: none;
}

.approachAIContainer .linkBox:visited {
color: $primary;
text-decoration: none;
}

.approachAIContainer .linkBox:hover{
border: 1px solid $secondary;
padding: 20px;
text-decoration: none;
}


  .productQuadrantsContainer {
    padding-top: 10px;
    background: #f6fcff;
    margin: 0 auto;
    width: 95%;
    height: 100%;
    display: flex;
    font-size: 16px;
    word-wrap: break-word;
  }

  .productQuadrantsContainer h5 {
    font-size: 18px;
  }

  .productQuadrantsContainer img {
    text-align: center;
    width: 50%;
  }

  /* About Us Section */

  .aboutUsPageContainer {
    flex-grow: 1;
    width: 90%;
    height: 100%;
    margin: 0 auto;
  }

  .aboutUsPageContainer .pageTitle {
    color: $primary2;
    font-weight: bold;
    font-size: 22px;
  }

  

  .aboutUsPageContainer .aboutUsInfoSection {
    margin: 0 auto;
  }

  .aboutUsPageContainer .aboutUsInfoSection .title {
    font-size: 22px;
    background-image: linear-gradient(to left, #dbebf6, #fdfeff);
    color: $primary;
    padding: 15px;
  }

  .aboutUsPageContainer .aboutUsInfoSection .description {
    font-size: 18px;
    color: #333;
    padding-bottom: 50px;
    padding-left: 25px;
    padding-right: 25px;
    height: auto;
  }

  .aboutUsPageContainer .profileContainer {
    margin: 25px auto;
  }

  .profileContainer .profileInfo {
    margin: 50px auto;
  }

  .profileContainer .profileInfo .name {
    font-size: 20px;
    color: $primary2;
    font-weight: bold;
  }

  .profileContainer .profileInfo .title {
    font-size: 18px;
    color: $primary;
  }

  .profileContainer .profileInfo .email {
    font-size: 15px;
    color: #7a99b0;
  }

  /* Video Demo */

  .videoDemoCarouselContainer {
    height: auto;
    width: 100%;
    padding-top: 30px;
    margin-top: 50px;
  }

  .videoDemoCarouselContainer video {
    width: 85%;
    height: auto;
  }

  .videoCarousel {
    padding-top: 50px;
    padding-bottom: 20px;
    background: $primary;
  }

  .videoDemoCarouselContainer h3 {
    font-size: 40px;
    margin-top: 50px;
  }

  .videoDemoCarouselContainer video {
    border-radius: 7px;
  }

  .videoDemoCarouselContainer h5 {
    color: $white;
    margin-top: 50px;
    font-size: 20px;
  }

  /* OFFERINGS Page Section */

  .offeringsContentContainer {
    flex-grow: $offeringsContentContainerFlex;
    width: $offeringsContentContainerWidth;
    height: $offeringsContentContainerHeight;
    margin: $offeringsContentContainerMargin;
  }
  
  .offeringsContentContainer a {
    text-decoration: none;
    color: $primary;
    font-size: 24px;
  }

  .offeringsContentContainer a:link {
    text-decoration: none;
    color: $primary;
    font-size: 24px;
  }

  .offeringsContentContainer a:hover {
    text-decoration: none;
    color: #74add5;
    font-size: 24px;
  }

  .offeringsContentContainer h5 {
    color: $offeringsH5Color;
  }

  .offeringsContentContainerOverview {
    color: #999;
    font-size: 24px;
  }

  /* FAQ Section */
  .faqContentContainer {
    flex-grow: 1;
    width: 100%;
    height: auto;
    margin: 25px auto;
    word-wrap: break-word;
  }

  .faqContentContainer .accordionContainer {
    background-image: linear-gradient(to bottom, #f3f8fc, #e1f1fc);
    padding: 25px;
    border-radius: 10px;
  }

  .faqContentContainer .category {
    font-size: 24px;
    color: #a4bfd0;
    text-align: left;
    padding: 20px;
    padding-left: 100px;
  }

  .faqContentContainer .titleLine {
    width: 90%;
    height: 2px;
    background-image: linear-gradient(to left, #f8fcff, #e1f1fc);
  }

  .faqContentContainer h5 {
    font-size: 20px;
  }

  .faqContentContainer p {
    margin: 0 auto;
    font-size: 18px;
    width: 100%;
    color: #333;
    text-align: center;
  }

  /* About Us Section */
  .aboutUsPageContainer {
    flex-grow: 1;
    width: 90%;
    height: 100%;
    margin: 0 auto;
  }

  .aboutUsPageContainer .pageTitle {
    color: $primary2;
    font-weight: bold;
    font-size: 22px;
  }

  .aboutUsPageContainer .aboutUsInfoSection {
    margin: 0 auto;
  }

  .aboutUsPageContainer .aboutUsInfoSection .title {
    font-size: 18px;
    background-image: linear-gradient(to left, #dbebf6, #fdfeff);
    color: $primary;
    padding: 15px;
  }

  .aboutUsPageContainer .aboutUsInfoSection .description {
    font-size: 18px;
    color: #333;
    padding-left: 25px;
    padding-right: 25px;
    height: 175px;
  }

   /* Capabilities */

  .capCard {
    width: auto;
    height: 450px;
    margin: 0 auto;
    text-align: center;
  }

  .capabilitiesContainer {
    height: 100%;
    padding-bottom: 25px;
  }

  /* Demo Videos Section */
  .demoContainer .videoThumbnailContainer {
    height: 800px;
    background: $cardColor;
    border-radius: 10px;
    overflow: auto;
    text-align: left;
    margin: 0;
  }

  .demoContainer .videoThumbnailContainer .Link:hover {
    background: $link;
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .demoContainer .contentContainer {
    background-image: linear-gradient(to bottom, #f1f9ff, #f6fcff);
    height: 100%;
  }

  .demoContainer .videoThumbnailContainer .listMediaTitle:hover {
    color: $white;
  }

  .demoContainer h6 {
    font-size: 14px;
  }

  .demoContainer small {
    color: $h3;
    opacity: 0.7;
    font-size: 12px;
    text-align: left;
  }

   /* Home Buttons sectiion - Landing Page */ 
    .homeButtons {
      margin: -10px auto;
      width: 100%;
      height: 125px;
    }

    .homeButtons .MuiButton-root {
      height: 65px;
      font-size: 14px;
      padding-top: 10px;
    }

    .homeButtons .MuiButtonGroup-root {
      margin-top:60px;
    }


    /* Partner Relations Card */

    .partnerRelationsContainer .MuiCard-root {
      background-color: white;
      color: black;
      height: 350px;
    }
    .partnerRelationsContainer .MuiCard-root:hover {
      background-color: #F5F5F5;
      color: $primary2;
    }

  .partnerRelationsContainer h5 {
    color: $grey;
    font-size: 22px;
  }
  /* Contact Us Container */
  .contactPageContainer {
    height: 100%;
  }
  .contactUsContentContainer {
   height: 100%;
  }

  .contactUsContentContainer h4 {
    color: $primary;
    font-size: 22px;

  }
    

    /* Investments */ 
    .investmentsContainer {
      margin: 0 auto;
    }
  
    .investmentsContainer .tabContentContainer {
      padding-left: 10px;
    }

    .investmentsContainer .tabContentContainer ul li{
      padding-bottom: 10px;
    }

    .investmentsContainer img {
      width: 95%;
      text-align: center;
      margin: 25px auto;
     }
  
    .investmentsContainer .tabContentContainer .category1 {
      color: $white;
      font-size: 26px;
      text-align: $left;
      text-transform: $pageTitleUppercase;
      background-image:  linear-gradient(to left, #7b9fc5, #2b6bb0);
      padding: $pageTitlePadding;
    }
  
    .investmentsContainer .tabContentContainer .category2 {
      color: $white;
      font-size: 26px;
      text-align: $left;
      text-transform: $pageTitleUppercase;
      background-image:  linear-gradient(to left, #f4c66b, #f3ba44);
      padding: $pageTitlePadding;
    }
  
    .investmentsContainer .tabContentContainer .category3 {
      color: $white;
      font-size: 26px;
      text-align: $left;
      text-transform: $pageTitleUppercase;
      background-image:  linear-gradient(to left, #a2ca7d, #7eaa55);
      padding: $pageTitlePadding;
    }

    .investmentsContainer	.MuiTab-root{
      background: $primary;
      color: $white;
    }
    
  /* Video Carousel Home Page Section */

  .videoDemoCarouselContainer {
    height: auto;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 30px;
  }

  .videoDemoCarouselContainer .sectionTitle {
    text-align: left;
    padding-left: 75px;
  }

  .videoDemoCarouselContainer .sectionDescription {
    font-size: $desktopFontSize;
    margin: 0 auto;
    text-align: left;
  }

  .videoDemoCarouselContainer .sectionDescription .title {
    margin-left: 40px;
  }

  .videoDemoCarouselContainer .sectionDescription .text {
    text-align: left;
    font-size: $desktopFontSize;
    width: 90%;
    margin: 0 auto;
  }

  .videoDemoCarouselContainer .button {
    margin-top: 15px;
    border-radius: 50px;
    height: 60px;
    padding: 15px;
    background-color: transparent;
    color: $primary;
    border: 2px solid $primary;
    font-size: 14px;
  }

  .videoDemoCarouselContainer video {
    width: 85%;
    height: auto;
    box-shadow: 0 10px 10px 0 #03365b;
  }

  .videoCarousel {
    padding-top: 50px;
    padding-bottom: 20px;
    background: $secondary;
  }

  .videoDemoCarouselContainer h3 {
    font-size: 40px;
    margin-top: 50px;
  }

  .videoDemoCarouselContainer video {
    border-radius: 7px;
  }

  .videoDemoCarouselContainer h5 {
    color: $primary;
    font-size: 22px;
  }

  /* Layer Cake */

  .layerCakeContent {
    border-radius: 10px;
    width: 100%;
    margin: 0 auto;
  }
  .layerCakeContentSideA,
  .layerCakeContentSideB {
    margin: 125px auto;
    font-family: sans-serif;
    height: 75vh;
  }

  .layerCakeContentSideA {
    border-radius: 10px 0 0 10px;
    background-image: linear-gradient(to bottom, #ffffff, #d8eefc);
    height: 40vh;
  }

  .layerCakeContentSideB {
    border-radius: 0 10px 10px 0;
    background-image: radial-gradient(#f2f2f2 1.5px, transparent 0.5px);
    background-size: 10px 10px;
    background-color: #ffffff;
    height: 75vh;
    margin-top: -110px;
  }

  .layerCakeLayerTitle {
    margin-top: 25px;
    padding-left: 50px;
    text-align: left;
    font-size: 15px;
    color: $white;
  }

  .layerCakeContentSideA .desc {
    width: 80%;
    margin: 0 auto;
    text-align: left;
    color: $white;
    font-size: 16px;
  }

  .layerCakeContentSideB .header {
    text-align: left;
    font-weight: 600;
    width: 90%;
    margin: 25px auto;
  }

  .layerLogo {
    width: 175px;
  }

  /* Component */

  .Card {
    text-align: center;
    background: transparent;
  }

  .Card .cardText {
    font-size: 16px;
    width: 75%;
    word-wrap: break-word;
    text-align: left;
    color: $body1;
    margin: 0 auto;
  }
  .Card h5 {
    color: $primary;
    font-size: 2vw;
    width: 85%;
    height: 75px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px 10px 0 0;
    color: #ffffff;
    word-wrap: break-word;
  }

  /* Profile Card Section */

  .ProfileCard {
    width: 100%;
    text-align: center;
  }

  .ProfileCard img {
    text-align: center;
    margin: 25px auto;
    height: 200px;
    border-radius: 10px;
    box-shadow: 5px 10px 8px 5px rgb(113, 113, 113);
    transform: rotate(2deg);
  }

  /* Badge */

  .badgeComponentContainer {
    float: left;
    margin: 0;
    padding: 20px;
  }
}
